import { useAuthUser } from "../useLogin";
import { useDispatch, useSelector } from "react-redux";
import { useApiClient } from "../useApiClient";
import { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import {
  SET_NOTIFICATIONS_LIST,
  SET_SHOW_ONLY_UNREAD_NOTIFICATIONS,
  UPDATE_READ_NOTIFICATION,
  UPDATE_ALL_READ_NOTIFICATION,
  DELETE_NOTIFICATION,
} from "../../souqh-redux/reducers/actionConstants";
import * as moment from "moment";
import {
  getDateFromTimestamp,
  getTimeFromTimestamp,
  covertTimeStampToDateTime,
} from "../../web/src/utils/AppUtils";
import {
  HOME_BUYER_USER,
  MY_TEMPLATES,
  OTHER_DOCUMENTS,
  ACTION_REQUIRED,
} from "../../web/src/components/ServiceProvider/serviceProviderConstants";
import { useActions } from "../useActions";
import { useUpcomingEeventsPendingActions } from "../serviceProvider/upcomingEeventsPendingActions/useUpcomingEeventsPendingActions";
import { PAGE_SIZE } from "../../web/src/components/HomeBuyer/SPManagement/spManagementConstants";
import { useSPUploadWizard } from "../serviceProvider/documents/useSPUploadWizard";
import { color_info } from "../toasterColors";
import { REFERENCE_DOCUMENTS } from "../../web/src/components/HomeBuyer/homeBuyerConstants";

const actionCreators = {
  setShowOnlyUnreadNotifications: (payload) => ({
    type: SET_SHOW_ONLY_UNREAD_NOTIFICATIONS,
    payload,
  }),
};

export const useNotifications = (reviewId) => {
  const { apiClient } = useApiClient();
  const userInfo = useAuthUser();
  const dispatch = useDispatch();
  const history = useHistory();
  const { acceptRejectProfileAccessRequest } =
    useUpcomingEeventsPendingActions();
  const scroller = useRef();
  const [todayNotifications, setTodayNotifications] = useState([]);
  const [olderNotifications, setOlderNotifications] = useState([]);
  const [displayNotifications, setDisplayNotifications] = useState([]);
  const [openViewInvoiceDialog, setOpenViewInvoiceDialog] = useState(false);
  const [viewInvoiceDialogData, setViewInvoiceDialogData] = useState(null);
  const [openAddReviewDialog, setOpenAddReviewDialog] = useState({
    open: false,
    data: {},
  });
  const [openProfileAccessRequestDialog, setOpenProfileAccessRequestDialog] =
    useState(false);
  const [profileAccessData, setProfileAccessData] = useState(null);
  const [openOtherActionDialog, setOpenOtherActionDialog] = useState(false);
  const [otherActionData, setOtherActionData] = useState(null);
  const [openDocumentAccessRequestDialog, setOpenDocumentAccessRequestDialog] =
    useState(false);
  const [requestedDocAccessName, setRequestedDocAccessName] = useState(null);
  const [docAccessRequestedData, setDocAccessRequestedData] = useState(null);
  const [documentAccessRequestorSP, setDocumentAccessRequestorSP] =
    useState(null);

  const [markAllRead, setMarkAllRead] = useState(false);
  const [scrollBottom, setscrollBottom] = useState(0);
  const [pageSize, setPageSize] = useState(0);

  const todaysDate = moment(new Date()).format("MMM DD, YYYY");
  const { notificationsList, showOnlyUnreadNotifications } = useSelector(
    (state) => state.notifications
  );

  const [openDocumetDialog, setOpenDocumetDialog] = useState(false);
  const [documentItem, setDocumentItem] = useState(null);

  // Signature required
  const [openSignDocumentDialog, setOpenSignDocumentDialog] = useState(false);
  const [signDocumentItem, setSignDocumentItem] = useState(null);
  const [openViewTagDialog, setOpenViewTagDialog] = useState(false);
  const [viewTagDialogData, setViewTagDialogData] = useState(null);

  const actions = useActions(actionCreators);

  const {
    openUploadWizard,
    actions: { setOpenUploadWizard },
  } = useSPUploadWizard();

  useEffect(() => {
    setDisplayNotifications(notificationsList.response);
  }, [notificationsList]);

  useEffect(() => {
    const todaysList =
      displayNotifications &&
      displayNotifications.length &&
      displayNotifications.filter(
        (el) =>
          moment(new Date(el.createdAt)).format("MMM DD, YYYY") === todaysDate
      );
    setTodayNotifications(todaysList);

    const remainingList =
      displayNotifications &&
      displayNotifications.length &&
      displayNotifications.filter((el) => !todaysList.includes(el));
    setOlderNotifications(remainingList);
  }, [displayNotifications]);

  useEffect(() => {
    if (showOnlyUnreadNotifications || markAllRead) {
      setDisplayNotifications(
        notificationsList.response.filter((el) => el.isRead === false)
      );
      setMarkAllRead(false);
    }
  }, [showOnlyUnreadNotifications, markAllRead]);

  useEffect(() => {
    if (scrollBottom) {
      if (
        notificationsList.response.length &&
        pageSize + 1 < notificationsList.totalPages
      ) {
        setPageSize((prevState) => ++prevState);
        getNotifications(pageSize + 1);
      }
    }
  }, [scrollBottom]);

  const handelScroll = (event) => {
    if (scroller && scroller.current) {
      if (
        scroller.current.scrollTop + scroller.current.offsetHeight >=
        scroller.current.scrollHeight
      ) {
        setscrollBottom((prevState) => ++prevState);
      }
    }
  };

  const getNotifications = (page = 0) => {
    dispatch({ type: "SET_LOADING", value: true });
    return apiClient
      .get(
        `/notification/all/${userInfo.userId}?page=${page}&size=${PAGE_SIZE}&unread=${showOnlyUnreadNotifications}`
      )
      .then(function (response) {
        if (response && response.data && response.data.response) {
          if (!page) {
            // if 1st page reset pageSize
            setPageSize(0);
          }
          response.data.currentPage = page;
          dispatch({
            type: SET_NOTIFICATIONS_LIST,
            payload: response.data,
          });
        }
      })
      .catch(function (error) {
        console.error(error);
      })
      .then(function () {
        dispatch({ type: "SET_LOADING", value: false });
      });
  };

  const getDescription = (description, eventFromDateTime) => {
    let replacedDescription = description.replace(
      "date_time_value",
      covertTimeStampToDateTime(eventFromDateTime)
    );
    replacedDescription = replacedDescription.replace(
      "date_value",
      getDateFromTimestamp(eventFromDateTime)
    );
    replacedDescription = replacedDescription.replace(
      "time_value",
      getTimeFromTimestamp(eventFromDateTime)
    );
    return replacedDescription;
  };

  const getAvatarUrl = (storefrontContent) => {
    return JSON.parse(storefrontContent).displayPicture;
  };

  const markSingleNotificationRead = (notificationId) => {
    dispatch({ type: "SET_LOADING", value: true });
    return apiClient
      .put(`/notification/mark-read/${notificationId}`)
      .then(function (response) {
        if (response && response.status === 200) {
          dispatch({
            type: UPDATE_READ_NOTIFICATION,
            payload: notificationId,
          });
        }
      })
      .catch(function (error) {
        console.error(error);
      })
      .then(function () {
        dispatch({ type: "SET_LOADING", value: false });
      });
  };

  const markAllNotificationsRead = () => {
    dispatch({ type: "SET_LOADING", value: true });
    return apiClient
      .put(`/notification/mark-read/all/${userInfo.userId}`)
      .then(function (response) {
        if (response && response.status === 200) {
          setMarkAllRead(true);
          dispatch({
            type: UPDATE_ALL_READ_NOTIFICATION,
            payload: null,
          });
        }
      })
      .catch(function (error) {
        console.error(error);
      })
      .then(function () {
        dispatch({ type: "SET_LOADING", value: false });
      });
  };

  const deleteNotification = (notificationId) => {
    dispatch({ type: "SET_LOADING", value: true });
    return apiClient
      .delete(`/notification/delete/${notificationId}`)
      .then(function (response) {
        if (response && response.status === 200) {
          dispatch({
            type: DELETE_NOTIFICATION,
            payload: notificationId,
          });
        }
      })
      .catch(function (error) {
        console.error(error);
      })
      .then(function () {
        dispatch({ type: "SET_LOADING", value: false });
      });
  };

  const getUploadedDocuments = (docDetails) => {
    if (!docDetails) {
      return [];
    }

    const fileName =
      decodeURIComponent(docDetails.documentName.replace(/^.*[\\\/]/, "")) ||
      "";
    let docObj = {
      name: fileName,
      path: docDetails.documentPreviewUrl,
      type: fileName.split(".").pop(),
      documentId: docDetails.documentId,
      signNowDocumentId: docDetails.signNowDocumentId,
      isReferenceDocument: docDetails.isReferenceDocument || true,
    };
    return docObj;
  };
  const handleNotification = (data) => {
   if(!data.isUserDeleted){
    if (!data.isRead) {
      markSingleNotificationRead(data.notificationId);
    }
    switch (data.notification) {
      //SP Notifications
      case "REVIEW_ADDED":
        history.push("clients/reviews");
        break;
      case "COMPLETE_CLIENT_TO_SP":
        history.push("/serviceprovider/clients?tabIndex=2");
        break;
      case "CLIENT_JOURNEY_STATUS_CHANGED_BY_HB":
        break;
      case "REQUEST_PROFILE_ACCESS_TO_SP":
        history.push("/serviceprovider/clients?tabIndex=1");
        break;
      case "NEW_LEAD_RECEIVED_TO_SP":
        history.push("/serviceprovider/clients?tabIndex=3");
        break;
      case "STOREFRONT_APPROVED_BY_SOUQH_ADMIN":
        history.push("/serviceprovider/dashboard");
        break;
      case "STOREFRONT_UPDATES":
        history.push("/serviceprovider/storefront");
        break;
      case "PROFILE_ACCESS_GRANTED_TO_SP":
        history.push(
          `/serviceprovider/clients/details/${data.requestorUserId}?serviceRequestId=${data.sfServiceRequestId}`
        );
        break;
      case "PROFILE_ACCESS_DENIED_TO_SP":
        history.push("/serviceprovider/clients?tabIndex=1");
        break;
      case "ADD_TEAM_MEMBER":
      case "TEAM_MEMBER_JOINS_SOUQH_TO_TEAM":
      case "CHANGE_MOBILE_NUMBER_BY_TEAM_MEMBER":
        history.push("/serviceprovider/settings/team");
        break;
      case "TAG_TEAM_MEMBER":
        if (userInfo.userType !== HOME_BUYER_USER) {
          history.push("/serviceprovider/clients?tabIndex=3");
        }
        break;
      case "TAG_SOMEONE":
        const viewTagdata = {
          requestorSPDetails: { email: data.sfServiceRequestDetails.email },
          tagComments: data.tagComments,
          dueDate: data.dueDate,
        };
        setViewTagDialogData(viewTagdata);
        if (userInfo.userType === HOME_BUYER_USER) {
          history.push("/homebuyer/events?tabIndex=1");
        } else {
          history.push("/serviceprovider/events?tabIndex=1");
        }
        setOpenViewTagDialog(true);
        break;
      case "CANCEL_REQUEST_TO_SP":
        history.push("/serviceprovider/clients?tabIndex=1");
        break;
      case "NEW_REFERRAL_SERVICE_PROVIDER_JOINED":
        history.push("/serviceprovider/messaging");
        break;
      case "SIGNATURE_REQUIRED":
        if (userInfo.userType === HOME_BUYER_USER) {
          history.push(`/homebuyer/documents?tabName=${ACTION_REQUIRED}`);
        } else {
          history.push(`/serviceprovider/documents?tabName=${ACTION_REQUIRED}`);
        }
        setOpenSignDocumentDialog(true);
        setSignDocumentItem(data.documentDetails);
        break;
      case "NEW_DOCUMENT_RECEIVED_TO_SP":
        history.push(`/serviceprovider/documents?tabName=${OTHER_DOCUMENTS}`);
        setDocumentItem(getUploadedDocuments(data.documentDetails));
        setOpenDocumetDialog(true);
        break;
      case "SIGNNOW_TEMPLATE_UPLOADED":
        history.push(`/serviceprovider/documents?tabName=${MY_TEMPLATES}`);
        break;
      case "DOCUMENT_ACCESS_DENIED":
        history.push(
          `/serviceprovider/clients/details/${data.requestorUserId}?tabIndex=1`
        );
        break;
      case "DOCUMENT_ACCESS_GRANTED":
        history.push(
          `/serviceprovider/clients/details/${data.requestorUserId}?tabIndex=1`
        );
        if (data.documentDetails.signNowDocumentId) {
          setSignDocumentItem(data.documentDetails);
          setOpenSignDocumentDialog(true);
        } else {
          setDocumentItem(getUploadedDocuments(data.documentDetails));
          setOpenDocumetDialog(true);
        }
        break;
      case "NEW_MEETING_TO_SP":
      case "DELETE_MEETING_TO_SP":
        if (userInfo) {
          if (userInfo.userType === HOME_BUYER_USER) {
            history.push("/homebuyer/calendar");
          } else {
            history.push("/serviceprovider/calendar");
          }
        }
        break;
      case "CLIENT_ASSIGNED":
        history.push("/serviceprovider/clients?tabIndex=0");
        break;
      case "NEW_LEAD_RECEIVED_TO_SP_FROM_BROADCAST":
        history.push("/serviceprovider/clients?tabIndex=3");
        break;
      //HB Notifications
      case "SEND_INVOICE":
        history.push("/homebuyer/events?tabIndex=1");
        setOpenViewInvoiceDialog(true);
        setViewInvoiceDialogData(data.invoiceDetailDTO);
        break;
      case "LEAD_ACCEPTED":
        history.push("/homebuyer/serviceproviders?tabIndex=0");
        break;
      case "LEAD_ACCEPTED_FOR_BROADCAST":
        history.push("/homebuyer/serviceproviders?tabIndex=1");
        break;
      case "LEAD_REJECTED":
        history.push("/homebuyer/serviceproviders?tabIndex=3");
        break;
      case "CANCEL_REQUEST":
        history.push("/homebuyer/serviceproviders?tabIndex=3");
        break;
      case "COMPLETE_CLIENT_TO_HB":
        history.push("/homebuyer/serviceproviders?tabIndex=2");
        const storefrontData = JSON.parse(data.storefrontAvatarUrl);
        setOpenAddReviewDialog({
          open: true,
          data: {
            serviceRequestId: data.sfServiceRequestDetails.sfServiceRequestId,
            storefrontId: storefrontData.storeFrontId,
            displayPicture: storefrontData.displayPicture,
            storefrontName: storefrontData.storefrontName,
            serviceName: data.sfServiceRequestDetails.serviceName,
            email: data.sfServiceRequestDetails.email,
            contactNumber: data.sfServiceRequestDetails.contactNumber,
          },
        });
        break;
      case "PROFILE_ACCESS_GRANTED":
        history.push("/homebuyer/serviceproviders?tabIndex=3");
        break;
      case "PROFILE_ACCESS_DENIED":
        history.push("/homebuyer/serviceproviders?tabIndex=3");
        break;
      case "REQUEST_PROFILE_ACCESS_TO_HB":
        history.push("/homebuyer/events?tabIndex=1");
        setOpenProfileAccessRequestDialog(true);
        setProfileAccessData({
          serviceRequestId: data.sfServiceRequestDetails.sfServiceRequestId,
          requestorSPDetails: { email: data.sfServiceRequestDetails.email },
        });
        break;
      case "REQUEST_PROFILE_UPDATE":
        history.push("/usersetup");
        break;
      case "CLIENT_JOURNEY_STATUS_CHANGED_BY_SP":
        history.push("/homebuyer/dashboard");
        break;
      case "REQUEST_ACCESS_TO_DOCUMENT":
        // First time "isDocumentAccessApproved" flag is null because no action has been performed
        // For rejection "isDocumentAccessApproved" is set to false
        // For accepted "isDocumentAccessApproved" is set tu true
        if (data.isDocumentAccessApproved === null) {
          setOpenDocumentAccessRequestDialog(true);
          setRequestedDocAccessName(data.documentDetails.documentName);
          setDocumentAccessRequestorSP(data.description.split(" has")[0]);
          setDocAccessRequestedData({
            requestorUserId: data.requestorUserId,
            referenceDocumentId: data.documentDetails.documentId,
            signnowDocumentId: data.documentDetails.signNowSouqhDocumentId,
          });
        } else if (data.isDocumentAccessApproved) {
          dispatch({
            type: "SET_TOASTER",
            payload: {
              message: `You have already granted access to the document`,
              bgColor: color_info,
              open: true,
            },
          });
        } else {
          dispatch({
            type: "SET_TOASTER",
            payload: {
              message: `You have already rejected access to the document`,
              bgColor: color_info,
              open: true,
            },
          });
        }
        break;
      case "REQUEST_DOCUMENT":
        history.push(`/homebuyer/documents?openUploadDocWizard=true`);
        break;
      case "CHANGE_PASSWORD":
      case "ENABLED_YOUR_2FA":
      case "CHANGE_YOUR_MOBILE_NUMBER":
        if (userInfo) {
          if (userInfo.userType === HOME_BUYER_USER) {
            history.push("/homebuyer/settings/account");
          } else {
            history.push("/serviceprovider/settings/account");
          }
        }

        break;
      case "NEW_DOCUMENT_RECEIVED_TO_HB":
        history.push(`/homebuyer/documents?tabName=${REFERENCE_DOCUMENTS}`);
        setDocumentItem(getUploadedDocuments(data.documentDetails));
        setOpenDocumetDialog(true);
        break;
      case "INACTIVE_SERVICE_REQUEST_TO_HB":
        history.push("/homebuyer/serviceproviders?tabIndex=3");
        break;
      //both users Notifications
      case "NEW_MEETING":
      case "UPDATE_MEETING":
      case "DELETE_MEETING":
        if (userInfo) {
          if (userInfo.userType === HOME_BUYER_USER) {
            history.push("/homebuyer/calendar");
          } else {
            history.push("/serviceprovider/calendar");
          }
        }
        break;
      case "MESSAGE_RECEIVED":
        if (userInfo) {
          if (userInfo.userType === HOME_BUYER_USER) {
            history.push("/homebuyer/messaging");
          } else {
            history.push("/serviceprovider/messaging");
          }
        }
        break;
      case "OTHER_ACTION_CLIENT_HUB":
        if (userInfo) {
          if (userInfo.userType === HOME_BUYER_USER) {
            history.push("/homebuyer/events?tabIndex=1");
          } else {
            history.push("/serviceprovider/events?tabIndex=1");
          }
        }
        setOpenOtherActionDialog(true);
        setOtherActionData(data.otherActionDetails);
        break;
      default:
        break;
    }
   }
  };
  return {
    getNotifications,
    notificationsList,
    todayNotifications,
    olderNotifications,
    getDescription,
    getAvatarUrl,
    handleNotification,
    actions,
    showOnlyUnreadNotifications,
    markAllNotificationsRead,
    openViewInvoiceDialog,
    setOpenViewInvoiceDialog,
    viewInvoiceDialogData,
    userInfo,
    displayNotifications,
    openAddReviewDialog,
    setOpenAddReviewDialog,
    openProfileAccessRequestDialog,
    setOpenProfileAccessRequestDialog,
    profileAccessData,
    setProfileAccessData,
    acceptRejectProfileAccessRequest,
    openOtherActionDialog,
    setOpenOtherActionDialog,
    otherActionData,
    handelScroll,
    scroller,
    openDocumentAccessRequestDialog,
    setOpenDocumentAccessRequestDialog,
    requestedDocAccessName,
    documentAccessRequestorSP,
    setOpenUploadWizard,
    openUploadWizard,
    docAccessRequestedData,
    openDocumetDialog,
    setOpenDocumetDialog,
    documentItem,
    setDocumentItem,
    openSignDocumentDialog,
    setOpenSignDocumentDialog,
    signDocumentItem,
    setSignDocumentItem,
    deleteNotification,
    openViewTagDialog,
    setOpenViewTagDialog,
    viewTagDialogData,
  };
};
