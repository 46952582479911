import { Button, Grid, Typography } from "@material-ui/core";
import React from "react";
import Styles from "../../../Styles.module.css";
// import FacebookLogin from "react-facebook-login";
// import InstagramLogin from "react-instagram-login";

export default function SyncSocialMediaFeed({
  save,
  apiClient,
  instagramToken,
  instagramCode,
}) {
  const { REACT_APP_FACEBOOK_LOGIN_LINK } = process.env;

  const redirectLink = window.location.href;

  const windowPathName = window.location.pathname;

  const responseFacebook = (response) => {
    console.log("response", response);
  };
  const componentClicked = (response) => {
    console.log("clicked");
  };
  const responseInstagram = (response) => {
    console.log(response);
  };

  function statusChangeCallback(response) {
    // Called with the results from FB.getLoginStatus().
    console.log("statusChangeCallback");
    console.log(response); // The current login status of the person.
    if (response.status === "connected") {
      // Logged into your webpage and Facebook.
      console.log("connected");
      // testAPI();
    } else {
      // Not logged into your webpage or we are unable to tell.
      document.getElementById("status").innerHTML =
        "Please log " + "into this webpage.";
    }
  }

  function checkLoginState() {
    // Called when a person is finished with the Login Button.
    console.log("check login");
    // FB.getLoginStatus(function (response) {
    //   // See the onlogin handler
    //   statusChangeCallback(response);
    // });
  }

  // let redirectLink = "";
  // const getRedirectURI = () => {
  //   const envName = process.env.REACT_APP_ENV_NAME;

  //   if (windowPathName.includes("setup")) {
  //     redirectLink =
  //       envName === "PROD"
  //         ? "https://souqh.ca/app/setup/storefront"
  //         : envName === "QA"
  //         ? window.location.origin.includes("preprod.souqh.co")
  //           ? "https://preprod.souqh.co/app/setup/storefront"
  //           : "https://demo.souqh.co/setup/storefront"
  //         : "https://development.souqh.co/setup/storefront";
  //     console.log("----", redirectLink);
  //   } else {
  //     redirectLink =
  //       envName === "PROD"
  //         ? "https://souqh.ca/app/serviceprovider/storefront"
  //         : envName === "QA"
  //         ? window.location.origin.includes("preprod.souqh.co")
  //           ? "https://preprod.souqh.co/app/serviceprovider/storefront"
  //           : "https://demo.souqh.co/serviceprovider/storefront"
  //         : "https://development.souqh.co/serviceprovider/storefront";
  //     console.log("---->>>>>", redirectLink);
  //   }
  //   return redirectLink;
  // };

  return (
    <Grid container item xs={12} id="service_details">
      <Grid style={{ marginTop: 60, marginBottom: 20 }} container>
        <Grid item xs={12}>
          <Typography
            variant="h4"
            className={Styles.navyBlueColor}
            style={{ marginBottom: 8 }}
          >
            Sync Social Media Feed
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography
            variant="body1"
            style={{ marginTop: 10 }}
            className={Styles.navyBlueColor}
          >
            Sync your social media posts from Facebook or Instagram directly on
            your storefront by clicking below
          </Typography>
        </Grid>
      </Grid>
      <Grid container justifyContent="space-around">
        <Grid item xs={6}>
          {/* <FacebookLogin
            appId="362097425096422"
            autoLoad={true}
            fields="name,email,picture"
            scope="public_profile,user_friends"
            callback={responseFacebook}
            onClick={componentClicked}
            icon="fa-facebook"
          /> */}

          <Button
            disabled
            variant="outlined"
            fullWidth
            className={Styles.navyBlueColor}
            style={{ textTransform: "none" }}
            startIcon={
              <img
                src="/images/facebook-icon.svg"
                style={{ height: "25px", width: "25px" }}
              />
            }
            // href={REACT_APP_FACEBOOK_LOGIN_LINK}
            onClick={() => {
              window.open(
                REACT_APP_FACEBOOK_LOGIN_LINK,
                "facebook login",
                "toolbar=no, menubar=no, width=700, height=700, top=100, left=300"
              );
            }}
          >
            Login with Facebook
          </Button>
        </Grid>
        <Grid item xs={6} style={{ paddingLeft: 8 }}>
          <Button
            disabled={instagramToken || instagramCode}
            variant="outlined"
            fullWidth
            className={Styles.navyBlueColor}
            style={{ textTransform: "none" }}
            startIcon={
              <img
                src="/images/Instagram-Logo.svg"
                style={{ height: "25px", width: "25px" }}
              />
            }
            // href={REACT_APP_GOOGLE_LOGIN_LINK}
            onClick={() => {
              save(
                (res) => {
                  if (res && res.data.status === 200) {
                    window.open(
                      // REACT_APP_GOOGLE_LOGIN_LINK,
                      `https://api.instagram.com/oauth/authorize?client_id=833083381134854&redirect_uri=${redirectLink}&scope=user_profile,user_media&response_type=code`,
                      // "instagram login",
                      // "toolbar=no, menubar=no, width=700, height=700, top=100, left=300"
                      "_self"
                    );
                  }
                },
                apiClient,
                true
              );
            }}
          >
            Login with Instagram
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
}
